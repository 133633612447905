import createUniveralCookie from './create-universal-cookie';
import createAuth0Client from '@auth0/auth0-spa-js';
import { jwtDecode } from 'jwt-decode';
import { AUTHENTICATION_VARIRABLES } from './constants/authentication_variables';
const { IdleDetector } = require('./idle-detector');
const { IDENTITY_TYPES } = require('./constants/identity_types');

let errorHandlerFunc;
let instanceResolve;
let instance = new Promise(resolve => {
    instanceResolve = resolve;
});

const REFRESH_INTERVAL = 5 * 60 * 1000;

export const AuthenticationErrorCodes = {
    MISSING_OR_MALFORMED_COOKIE: 'missingOrMalformedCookie',
    NOT_SIGNED_IN: 'notSignedIn',
    REFRESH_TOKEN_FAILED: 'refreshTokenFailed',
    AUTH0_REFRESH_FAILED: 'auth0RefreshFailed',
    USER_IS_INACTIVE: 'userIsInactive'
};

const gydaCookie = {
  token: "gydaToken",
  remember: "gydaRemember",
};

export class AuthenticationClient {
    constructor({ baseUri, domain, identityUri, cookieDomain, auth0_domain, auth0_audience, auth0_client_id, logoutUrl }) {
        this.baseUri = baseUri;
        this.domain = domain
        this.identityUri = identityUri;
        this.cookieDomain = cookieDomain;
        this.auth0_domain = auth0_domain;
        this.auth0_audience = auth0_audience;
        this.auth0_client_id = auth0_client_id;
        this.logoutUrl = logoutUrl;
        this.token = null;
        this.userCompanyPromise = null;
        this.initialized = false;

        this._cookie = new createUniveralCookie();

        this.init();
        instanceResolve(this);
    }

    init() {
        if(this.initialized) {
            return;
        }
        this.initialized = true;

        this.token = this._getInitialToken();
        this.auth0Client = this._getAuth0Client();
        this.refreshToken().then(async () => {
            if(!this.token) {
                return;
            }
            const userInfo = jwtDecode(this.token);
            if(!(userInfo.workspace && userInfo.workspace._id)) {
                return;
            }
            const currentWorkspace = userInfo.workspace._id;
            if (currentWorkspace) {
                const currentLocation = new URL(window.location.href);

                const redirectWorkspaceId = currentLocation.searchParams.get("workspaceId");
                currentLocation.searchParams.delete("workspaceId");

                if ( redirectWorkspaceId !== null && currentWorkspace !== redirectWorkspaceId ) {
                    const newCompanyUrl = `${this.domain}/switchWorkspace/${redirectWorkspaceId}?redirectTo=${currentLocation}`;
                    window.location.assign(newCompanyUrl);
                }
            }
        });
        installRefreshInterval(this.refreshToken.bind(this));
    }

    async getCurrentUserId() {
        return await this.getCurrentIdentityInformation(IDENTITY_TYPES.USER);
    }

    async getCurrentCompanyId() {
        return await this.getCurrentIdentityInformation(IDENTITY_TYPES.COMPANY);
    }

    async getActiveWorkspaceId() {
        return await this.getCurrentIdentityInformation(IDENTITY_TYPES.WORKSPACE);
    }

    async getCurrentIdentityInformation(identityType) {
        if(!this.userCompanyPromise) {
            this.userCompanyPromise = fetch(`${this.identityUri}/`, {
                headers: {
                    Authorization: this.token,
                    'x-client-name': AUTHENTICATION_VARIRABLES.CLIENT_NAME
                }
            })
                .then(response => {
                    if(!response.ok) {
                        throw new Error(`AuthenticationClient - bad response from identity.`);
                    }
                    return response.json();
                })
                .catch(() => {
                    this.userCompanyPromise = null;
                    throw new Error(`AuthenticationClient failed to get ${identityType} information.`);
                });
        }
        return this.userCompanyPromise
            .then(payload => {
                switch(identityType) {
                    case IDENTITY_TYPES.USER:
                        return payload.user._id;
                    case IDENTITY_TYPES.COMPANY:
                        return payload.company._id;
                    case IDENTITY_TYPES.WORKSPACE:
                        return payload.companyMembership.activeWorkspaceId;
                }
            });
    }

    async getToken() {
        if(!this.token) {
            this.throwError(AuthenticationErrorCodes.NOT_SIGNED_IN);
        }

        return this.token;
    }

    async refreshToken() {
        if(!this.refreshPromise) {
            const idleDetector = await IdleDetector.getInstance();
            const gydaRemember = this._cookie.get('gydaRemember', { domain: this.cookieDomain });

            this.refreshPromise = fetch(`${this.baseUri}/auth/resetToken?rememberMe=${gydaRemember === "1"}&isActive=${!idleDetector.isIdle()}`, {
                headers: {
                    Authorization: this.token
                }
            })
                .then(response => {
                    if (response.ok) {
                        // If the response is in the range of 200-299 consider is successful and return the response.
                        return response.json();
                    } else if (response.status === 401) {
                        // If the reponse returns a 401 we can assume our request lacks valid authentication credentials based off
                        // the current gydaToken.
                        throw AuthenticationErrorCodes.MISSING_OR_MALFORMED_COOKIE;
                    } else if (response.status === 417) {
                        // User has been inactive for longer than allowed, log them out.
                        throw AuthenticationErrorCodes.USER_IS_INACTIVE;
                    } else {
                        // If the response is not ok throw an error to let the client know.
                        // This check may be reduntant due to the .catch
                        throw AuthenticationErrorCodes.REFRESH_TOKEN_FAILED;
                    }
                })
                .then((response) => {
                    this.token = response.token;

                    let expireDays = 1;
                    if(gydaRemember === "1"){
                        expireDays = 7;
                    }
                    var exDate = new Date();
                    exDate.setDate(exDate.getDate() + expireDays);

                    // clear cookie with default path/domain if still exists
                    this._cookie.remove(gydaCookie.token, { domain: this.cookieDomain });
                    this._cookie.remove(gydaCookie.remember, { domain: this.cookieDomain });

                    const cookieSettings = {
                        expires: exDate,
                        path: '/',
                        domain: this.cookieDomain,
                        secure: true
                    }

                    this._cookie.set(gydaCookie.token, JSON.stringify(response), cookieSettings);
                    this._cookie.set(gydaCookie.remember, gydaRemember, cookieSettings);
                    this.refreshPromise = null;
                }).then(() => {
                    return this.refreshAuth0Token();
                })
                .catch((e) => {
                    let error;
                    if(e.message && e.message.indexOf(AuthenticationErrorCodes.AUTH0_REFRESH_FAILED) > -1) {
                        console.warn('Auth0 refresh failed fail silently');
                        return;
                    }

                    // User is inactive, don't throw error, just log them out.
                    if (e === AuthenticationErrorCodes.USER_IS_INACTIVE) {
                        return this.logout();
                    }

                    // Some other error, find the correct one and throw the error.
                    if (Object.values(AuthenticationErrorCodes).indexOf(e) > -1) {
                        error = e;
                    } else {
                        error = AuthenticationErrorCodes.REFRESH_TOKEN_FAILED;
                    }

                    this.throwError(error);
                });
        }
        return this.refreshPromise;
    }

    /**
     * Remove the Gyda cookies and call the gaf-idp /logout endpoint to log the user out. Right now this is only being
     * used for user inactivity.
     *
     * @return {void}
     */
    logout() {
        // Remove old cookie references and redirect to logout page.
        this._cookie.remove(gydaCookie.token, { domain: this.cookieDomain });
        this._cookie.remove(gydaCookie.remember, { domain: this.cookieDomain });
        this.token = null;

        this.navigateToLogout();
    }

    /**
     * Convenience function for navigating to logout URL. This is exposed via a function for testing purposes!
     */
    navigateToLogout() {
        window.location.href = `${this.logoutUrl}?redirectTo=${encodeURIComponent(window.location.href)}&status=inactive`;
    }

    async _getAuth0Client() {
        if(!this.auth0Client) {
            this.auth0Client = await createAuth0Client({
                domain: this.auth0_domain,
                audience: this.auth0_audience,
                client_id: this.auth0_client_id,
                useRefreshTokens: true
            });
        }

        return this.auth0Client;
    }

    async refreshAuth0Token() {
        try {
            const client = await this._getAuth0Client();
            return await client.getTokenSilently();
        } catch (e) {
            this.throwError(AuthenticationErrorCodes.AUTH0_REFRESH_FAILED);
        }

    }

    _getInitialToken() {
        try {
            const cookieValue = this._cookie.get('gydaToken', {domain: this.cookieDomain});
            return cookieValue.token;
        } catch(error) {
            this.throwError(AuthenticationErrorCodes.MISSING_OR_MALFORMED_COOKIE);
        }
    }

    throwError(reason) {
        if(errorHandlerFunc) {
            errorHandlerFunc(reason);
        } else {
            throw new Error(`AuthenticationClient - No error handler function registered. ErrorCode: ${reason}`);
        }
    }

    static onError(passedErrorHandlerFunc) {
        if(errorHandlerFunc) {
            throw new Error('AuthenticationClient.onError can only be invoked once.');
        } else {
            errorHandlerFunc = passedErrorHandlerFunc;
        }
    }

    static async getInstance() {
        return instance;
    }
}

function installRefreshInterval(refreshToken) {
    setInterval(refreshToken, REFRESH_INTERVAL);
}
