import { AUTHENTICATION_VARIRABLES } from './constants/authentication_variables';

const AuthenticationClient = require('./authentication').AuthenticationClient;

let instanceResolve;
let instance = new Promise(resolve => {
    instanceResolve = resolve;
});

export class UserClient {
    constructor({ identityUri }) {
        this.identityUri = identityUri;
        this.userPromise = null;

        instanceResolve(this);
    }

    async getCurrentUser() {
        const authenticationClient = await AuthenticationClient.getInstance();
        const authToken = await authenticationClient.getToken();
        const currentUserId = await authenticationClient.getCurrentUserId();

        if(!this.userPromise) {
            this.userPromise = fetch(`${this.identityUri}/`, {
                headers: {
                    Authorization: authToken,
                    'x-client-name': AUTHENTICATION_VARIRABLES.CLIENT_NAME
                }
            })
            .then(response => {
                if(!response.ok) {
                    throw new Error(`UserClient - bad response from user endpoint. User Id: ${currentUserId}`);
                }
                return response.json();
            })
            .catch((error) => {
                this.userPromise = null;
                throw new Error(`UserClient - Failed to fetch user by id. User Id: ${currentUserId}. ${error}`);
            });
        }
        return this.userPromise
            .then(payload => {
                return {
                    id: payload.user._id,
                    firstName: payload.user.firstName,
                    lastName: payload.user.lastName,
                    email: payload.user.email,
                    timezone: payload.user.timezone,
                    language: payload.user.language,
                    appPermissions: payload.appPermissions,
                    role: payload.companyMembership.role,
                    activeWorkspaceId: payload.companyMembership.activeWorkspaceId
                };
            });
    }

    static getInstance() {
        return instance;
    }
}
