import { AUTHENTICATION_VARIRABLES } from './constants/authentication_variables';

const AuthenticationClient = require('./authentication').AuthenticationClient;

let instanceResolve;
let instance = new Promise(resolve => {
    instanceResolve = resolve;
});

export class AppSettingsClient {
    constructor({ identityUri }) {
        this.identityUri = identityUri;
        this.appSettingsPromises = {};

        instanceResolve(this);
    }

    async getAppSettings(settingName) {
        const authenticationClient = await AuthenticationClient.getInstance();
        const authToken = await authenticationClient.getToken();
        const currentUserId = await authenticationClient.getCurrentUserId();

        if(!this.appSettingsPromises[settingName]) {
            this.appSettingsPromises[settingName] = fetch(`${this.identityUri}/users/${currentUserId}/app_settings/${settingName}`, {
                headers: {
                    Authorization: authToken,
                    'x-client-name': AUTHENTICATION_VARIRABLES.CLIENT_NAME
                }
            })
            .then(response => {
                if(!response.ok) {
                    throw new Error(`AppSettingsClient - bad response from app_settings endpoint. User Id: ${currentUserId}, Setting name: ${settingName}`);
                }
                return response.json();
            })
            .catch((error) => {
                this.appSettingsPromises[settingName] = null;
                throw new Error(`AppSettingsClient - Failed to fetch app settings by user id. User Id: ${currentUserId}, Setting Name: ${settingName}. ${error}`);
            });
        }
        return this.appSettingsPromises[settingName];
    }

    static getInstance() {
        return instance;
    }
}
