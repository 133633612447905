import { AUTHENTICATION_VARIRABLES } from './constants/authentication_variables';

const AuthenticationClient = require('./authentication').AuthenticationClient;

let instanceResolve;
let instance = new Promise(resolve => {
    instanceResolve = resolve;
});

async function fetchCurrentUsersCompanyAndCompanies(identityUri, options) {
    options = options || {};

    const authenticationClient = await AuthenticationClient.getInstance();
    const authToken = await authenticationClient.getToken();
    const currentUserId = await authenticationClient.getCurrentUserId()
    const currentCompanyId = await authenticationClient.getCurrentCompanyId();

    return fetch(`${identityUri}/users/${currentUserId}/companies?synchronizeOpportunities=${!!options.synchronizeOpportunities}`, {
        headers: {
            Authorization: authToken,
            'x-client-name': AUTHENTICATION_VARIRABLES.CLIENT_NAME
        }
    })
    .then(response => {
        if(!response.ok) {
            throw new Error(`UserCompanyClient - bad response from Identity /users/<userId>/companies endpoint. User Id: ${currentUserId}`);
        }
        return response.json();
    })
    .then(payload => {
        const company = {};
        const companies = [];

        for(let index = 0; index < payload.length; index++) {
            let rawCompany = payload[index].company;
            if(rawCompany._id === currentCompanyId) {
                company.id = rawCompany._id;
                company.name = rawCompany.name;
                companies.push({
                    id: rawCompany._id,
                    name: rawCompany.name,
                    active: true
                });
            } else {
                companies.push({
                    id: rawCompany._id,
                    name: rawCompany.name,
                    active: false
                });
            }
        }

        return {
            company,
            companies
        };
    })
}

export class UserCompanyClient {
    constructor({ identityUri }) {
        this.identityUri = identityUri;
        this.usersCompanyAndCompaniesPromise = null;

        instanceResolve(this);
    }

    async getCurrentUsersCompany() {
        if(!this.usersCompanyAndCompaniesPromise) {
            this.usersCompanyAndCompaniesPromise = fetchCurrentUsersCompanyAndCompanies(this.identityUri)
            .catch((error) => {
                this.usersCompanyAndCompaniesPromise = null;
                throw new Error(`UserCompanyClient - Failed to fetch the users company by user id. ${error}`);
            });
        }
        return this.usersCompanyAndCompaniesPromise
            .then(payload => {
                return payload.company;
            });
    }

    async getCurrentUsersCompanies(options) {
        if(!this.usersCompanyAndCompaniesPromise) {
            this.usersCompanyAndCompaniesPromise = fetchCurrentUsersCompanyAndCompanies(this.identityUri, options)
            .catch((error) => {
                this.usersCompanyAndCompaniesPromise = null;
                throw new Error(`UserCompanyClient - Failed to fetch the users companies by user id. ${error}`);
            });
        }
        return this.usersCompanyAndCompaniesPromise
            .then(payload => {
                return payload.companies;
            });
    }

    static getInstance() {
        return instance;
    }
}
