const { AUTHENTICATION_VARIRABLES } = require('./constants/authentication_variables');
import { Idle } from 'idlejs';

// I don't really get the point of this versus a regular static instance instantiation but keeping it consistent
// with the rest of the library.
let instanceResolve;
let instance = new Promise((resolve) => {
    instanceResolve = resolve;
})

export class IdleDetector {
    constructor() {
        this.isUserIdle = false;

        // Start the idle timer. If there's no activity in the tab within USER_IDLE_TIMEOUT_MINUTES, 
        // we will set that we're inactive and notify the backend the next time we refresh the token.
        // When logged back in, the timer resets to another USER_IDLE_TIMEOUT_MINUTES window.
        this.idle = new Idle()
            .whenNotInteractive()
            .within(AUTHENTICATION_VARIRABLES.USER_IDLE_TIMEOUT_MINUTES)
            .do(() => this._setIsIdle(true))
            .start();

        instanceResolve(this);
    }

    /**
     * Set an instance variable for when we detect the user is idle. Starts as false. If true, the user is idle.
     *
     * @param isIdle True for idle, false otherwise.
     * @private
     */
    _setIsIdle(isIdle) {
        this.isUserIdle = isIdle;
    }

    /**
     * Get whether the user is idle in this tab.
     * @return {boolean}
     */
    isIdle() {
        return this.isUserIdle;
    }

    static async getInstance() {
        return instance;
    }
}
