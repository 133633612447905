import { UserClient } from './user';
import { UserCompanyClient } from './user-company';
import { AuthenticationClient } from './authentication';
import { AppSettingsClient } from './app-settings';
import { IdleDetector } from './idle-detector';

let initializedResolve;
let initialized = new Promise((resolve) => {
    initializedResolve = resolve;
})

export const Environments = {
    STAGING: 'staging',
    PRODUCTION: 'production'
}

export class Configuration {
    static getConfiguration(env) {
        if(env === Environments.STAGING) {
            return {
                baseUri: 'https://live.staging.gaf-identity-provider.meltwater.io',
                domain: 'https://staging.meltwater.net',
                identityUri: 'https://v1.staging.identity.meltwater.io',
                cookieDomain: '.meltwater.net',
                auth0_domain: 'authorize.meltwater.net',
                auth0_audience: 'https://authorize.meltwater.net/api/v2/',
                auth0_client_id: "BY1Z1duLf8sEwLj3PyQ4KkcTOjGXvX7z",
                logoutUrl: "https://staging.meltwater.net/logout"
            }
        } else if (env === Environments.PRODUCTION) {
            return {
                baseUri: 'https://live.gaf-identity-provider.meltwater.io',
                domain: 'https://app.meltwater.com',
                identityUri: 'https://v1.identity.meltwater.io',
                cookieDomain: '.meltwater.com',
                auth0_domain: 'authorize.meltwater.com',
                auth0_audience: 'https://authorize.meltwater.com/api/v2',
                auth0_client_id: "sy6sQF2zJZWJd1jqupARpRuUIEl9xyH6",
                logoutUrl: "https://app.meltwater.com/logout"
            }
        } else {
            throw new Error(`Configuration - unknown environment provided. Provided Value ${env}`);
        }
    }
}

export class Initializer {
    static initialize(env) {
        new AuthenticationClient(Configuration.getConfiguration(env));
        new UserClient(Configuration.getConfiguration(env));
        new UserCompanyClient(Configuration.getConfiguration(env));
        new AppSettingsClient(Configuration.getConfiguration(env));
        new IdleDetector();
        initializedResolve();
    }
    static onInitialized() {
        return initialized;
    }
}
